import React from "react";
import {
  Call,
  Close,
  Email,
  LinkSharp,
  Pinterest,
  Telegram,
  WhatsApp,
  YouTube,
} from "@material-ui/icons";
import {
  Database,
  Facebook,
  Instagram,
  Phone,
  PhoneCall,
  Share2,
  Twitter,
} from "react-feather";
import { Col, NavLink, Row } from "reactstrap";
import logo from "../../../assets/img/logo/logo.webp";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import Technical from "../../../assets/img/logo/Technical professional.png";
import { FiYoutube } from "react-icons/fi";
import { MdOutlineWhatsapp } from "react-icons/md";
import { SiAparat } from "react-icons/si";
import { FaLocationDot } from "react-icons/fa6";
import data from "../../../assets/data/configs/storeConfig.json";
import zarin from "../../../assets/img/elements/zarin.png";
import { history } from "../../../history";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whatsapp: false,
      enamad: true,
    };
  }

  handleSupport = (targetPath) => {
    if (targetPath) history.push(targetPath);
    this.setState({
      support: !this.state.support,
    });
  };

  support = () => {
    this.setState({
      whatsapp: !this.state.whatsapp,
    });
  };

  renderSocialIcons = (item) => {
    let n = item.link.lastIndexOf("/");
    let mediaId = item.link.substring(n + 1);
    switch (item.media) {
      case "instagram":
        return (
          <a href={item.link}>
            <span>
              {" "}
              <Instagram />
            </span>
          </a>
        );
      case "telegram":
        return (
          <a href={item.link}>
            <span>
              {" "}
              <Telegram />
            </span>
          </a>
        );
      case "whatsApp":
        return (
          <a href={item.link}>
            <span>
              {" "}
              <WhatsApp />
            </span>
          </a>
        );
      case "pinterest":
        return (
          <a href={item.link}>
            <span>
              {" "}
              <Pinterest />
            </span>
          </a>
        );
      case "youtube":
        return (
          <a href={item.link}>
            <span>
              {" "}
              <YouTube />
            </span>
          </a>
        );
      case "twitter":
        return (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            title={mediaId}
          >
            <Twitter className="icon-twitter" />
          </a>
        );
      case "faceBook":
        return (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            title={mediaId}
          >
            <Facebook className="icon-facebook" />
          </a>
        );
      default:
        return null;
    }
  };
  render() {
    return (
      <>
        <div className="web-footer">
          <div className="bg-black-footer ">
            <Row>
              <Col md={4} sm={12}>
                <div className='footer-item-background'>
                  <div className="footer-logo">
                    <h1>{data.sectionName}</h1>
                  </div>
                  <div className="footer-desc">
                    <p>
                      {data.shortAbout}
                    </p>
                  </div>
                  <div className="footer-social">
                    {data.socialMedia.map((item) => this.renderSocialIcons(item))}
                  </div>
                </div>
              </Col>
              <Col md={8} sm={12}>
                <Row>
                  {/* <Col md={3} sm={12}> */}
                    {/* <h5 className="title-footer">لینک های مهم</h5> */}
                    {/* <div className="footer-speed-container"> */}
                      {/* <div className="footer-link-speeed"> */}
                        {/* <a href="https://www.fide.com/">فدراسیون جهانی شطرنج</a> */}
                      {/* </div> */}
                    {/* </div> */}
                  {/* </Col> */}
                  <Col md={4} sm={12} >
                   <div className='footer-item-background'>
                     <h5 className="title-footer">لینک دسترسی سریع</h5>
                     <div className="footer-speed-container">
                       <div className="footer-link-speeed">
                         <a href="/">صفحه اصلی</a>
                       </div>
                       <div className="footer-link-speeed">
                         <a href="/contact-us"> تماس با ما</a>
                       </div>
                       <div className="footer-link-speeed">
                         <a href="/about-us">درباره ما</a>
                       </div>
                       <div className="footer-link-speeed">
                         <a href="/articles">مقالات</a>
                       </div>
                       <div className="footer-link-speeed">
                         <a href="/gallery">گالری</a>
                       </div>
                       <div className="footer-link-speeed">
                         <a href="/rules">قوانین و مقررات</a>
                       </div>
                     </div>
                   </div>
                  </Col>
                  <Col md={4} sm={12}>
                    <div className='footer-item-background'>
                     <h5 className="title-footer">تماس با ما</h5>

                     {/* <div className="footer-location">
                      <span>
                        <FaLocationDot />
                        آدرس:
                      </span>
                      <p>{data.address}</p>
                    </div> */}

                     <div className="footer-phone">
                      <span>
                        <Call />
                        تلفن:
                      </span>
                       {data.mobile.map((item) => (
                           <a href={`tel:${item}`}>
                             <Phone /> {item}
                           </a>
                       ))}
                     </div>
                   </div>
                  </Col>
                  <Col md={4} sm={12}>
                  <div className='footer-item-background'>
                    <h5 className="title-footer">به ما اعتماد کنید</h5>
                    <div className="namad d-flex align-items-center">
                      <div className="namad-container ">
                        <img src={zarin} className="w-75" />
                      </div>
                      <div className="namad-container mt-namad">
                        <a
                            referrerPolicy="origin"
                            target="_blank"
                            href="https://trustseal.enamad.ir/?id=453566&Code=T7FjZ30evL5tisEZVxL5fvnSTN8bY6mb"
                        >
                          <img
                              className="w-100"
                              referrerPolicy="origin"
                              src="https://trustseal.enamad.ir/logo.aspx?id=453566&Code=T7FjZ30evL5tisEZVxL5fvnSTN8bY6mb"
                              alt=""
                              style={{ cursor: "pointer" }}
                              Code="T7FjZ30evL5tisEZVxL5fvnSTN8bY6mb"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="border-footer"></div>

          <div className="copyright p-1 w-100 m-auto">
                        <p className="m-0">
                            {'کلیه حق و حقوق وبسایت برای '}
                            <a href="https://gambitchess.ir">
                                <strong className='text-bold'>
                                    {data.sectionName}
                                </strong>
                            </a>
                            {' محفوظ است. '}
                        </p>
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                {' این وبسایت توسط '}
                                <a href="https://barantm.com">
                                    <strong className='text-bold'>
                                        شرکت برنامه نویسی باران
                                    </strong>
                                </a>
                                {'  طراحی و تولید شده است.'}
                            </p>
                        </a>

                    </div>
        </div>

        <div className="copyright copyright-phone p-1 w-100 m-auto">
          <p className="m-0">
            کلیه حق و حقوق وبسایت برای آکادمی شطرنج گامبی محفوظ است
          </p>
        </div>

        {/* <div className="div-contact">
          <button className="btn-contact">
            <a href="tel:09102288566">
              <PhoneCall />
            </a>
          </button>
        </div> */}
      </>
    );
  }
}

export default Footer;
