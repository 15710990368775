import React from "react";
import {connect} from "react-redux";
import {logout} from "../../../redux/actions/auth/loginActions";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import Menu from "components/Web/Headers/Menu"
import * as Icon from "react-feather";
import {ListGroup} from 'react-bootstrap';
import {NavLink as RNavLink} from "reactstrap";
import MobileNavbar from "./MobileMenuRender/Navbar";
import logo1 from "assets/img/logo/logo.webp";
import PersonIcon from "@material-ui/icons/Person";
import {GetRequest} from "../../request";
import Notify from "components/Baran/noty/notify";
import {history} from "../../../history";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {Roles} from "../../roles/ProjectRole";
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader, Row,
    UncontrolledDropdown
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {ContextLayout} from "../../../layouts/PageLayout";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import {CloseSharp, Fingerprint, WhatsApp} from "@material-ui/icons";
import android from "../../../assets/img/logo/logo.webp";
import play from "../../../assets/img/downloads/androidPlay.png";
import bazaar from "../../../assets/img/downloads/bazaar.png";
import myket from "../../../assets/img/downloads/myket.png";
import apple from "../../../assets/img/icons/apple.png";
import sibiranilogobanner from "../../../assets/img/downloads/sibiranilogobanner.png";
import IappsBadgeGray from "../../../assets/img/downloads/IappsBadgeGray.png";
import anardoniBadgeDarkPersian from "../../../assets/img/downloads/anardoniBadgeDarkPersian.png";
import {HiOutlineUser} from 'react-icons/hi'
import logo from '../../../assets/img/logo/logo.webp'
import {AiFillHome, AiOutlineArrowUp, AiOutlineClose} from 'react-icons/ai'
import {HiOutlinePhone} from 'react-icons/hi'
import {TbDeviceMobile, TbUser} from 'react-icons/tb'
import scrollWindowTop from "../../../utility/scrollWindowTop";
import {RiSearch2Line} from "react-icons/ri"
import {FaInstagram} from "react-icons/fa";

import {
    ChevronsLeft,
    ChevronsRight,
    Instagram,
    Mail,
    MapPin,
    Phone,
    User
} from "react-feather";
import {Tooltip} from "@material-ui/core";
import  data  from "assets/data/configs/storeConfig.json";


let searchDelay = 0;

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doesShowSearchBar: false,
            searchValue: "",
            position: 0,
            modal: false,
            searchUrl: "/api/home/web/search",
            courses: [],
            course_categories: [],
            articles: [],
            article_categories: [],
            searchBox: false,
            dropdownUser: false,
            support: false,
            modal_download: false,
            mobileClick: false,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
     

        if (this.state.position) {
            const listenToScroll = () => {
                const winScroll =
                    document.body.scrollTop || document.documentElement.scrollTop

                const height =
                    document.documentElement.scrollHeight -
                    document.documentElement.clientHeight

                const scrolled = winScroll / height

                this.setState(scrolled)
            }
            window.addEventListener('scroll', listenToScroll)
            return () => window.removeEventListener('scroll', listenToScroll);
        }

    }

    getData = () => {
        GetRequest(`${this.state.searchUrl}?s=${this.state.searchValue}`,
            {
                'Authorization': this.props.user.token,
            }, true).then((response) => {
            let result = response.data;
            if (result.ok) {
                result = result.data;
                this.setState({
                    courses: result.courses,
                    course_categories: result.course_categories,
                    articles: result.articles,
                    article_categories: result.article_categories
                })
            }
        }).catch(function (error) {
            Notify('error', 'top-right', "خطایی رخ داده است");
        });
    }

    showSearchBar = () => {
        this.setState({
            doesShowSearchBar: !this.state.doesShowSearchBar
        })
    }

    searchHandler = (e) => {
        clearTimeout(searchDelay)
        this.setState({
            searchValue: e.target.value,
            closeList: false
        })
        if (this.state.searchValue.length > 0) {
            searchDelay = setTimeout(async () => {
                this.getData();
            }, 800);
        }
    }

    handleSearchClick = () => {
        history.push("/search?s=" + this.state.searchValue);
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            history.push("/search?s=" + this.state.searchValue);
        }
    }

    closeResults = () => {
        this.setState({
            closeList: !this.state.closeList,
        })
    }

    goToPanel = () => {
        if (this.props.user.roles.includes(Roles.Student))
            history.push('/panel/my-courses')
        else if (this.props.user.roles.includes(Roles.Teacher))
            history.push('/panel/teacher-courses')
        else
            history.push('/panel/dashboard')
    }

    exit = () => {
        if (this.props.user.token)
            this.props.logout()
    }

    /* toggleSearch = () => {
         this.setState({
             searchBox: !this.state.searchBox
         })
     }*/

    toggleDropdown = (bool) => {
        this.setState({
            dropdownUser: bool
        })
    }

    toggleSupport = () => {
        this.setState({
            support: !this.state.support
        })
    }

    toggleModalDownload = () => {
        this.setState({
            modal_download: !this.state.modal_download
        })
    }

    download = (url) => {
        window.open(url, "_blank")
    }

    render() {
        const searchbar =
            <>
                {
                    <div className="d-flex search-field">
                        {this.state.searchValue ?
                            <ListGroup className={this.state.closeList ? "d-none" : "search-results"}
                                       onMouseLeave={() => this.closeResults()}>
                                {this.state.courses.length > 0 ?
                                    <>
                                        <label className="d-flex justify-content-between">
                                            <span>دوره ها</span>
                                            <NavLink to={"/search?s=" + this.state.searchValue}
                                                     className="bg-transparent border-0">مشاهده بیشتر</NavLink>
                                        </label>
                                        {this.state.courses.map((item, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        className={`w-100 ${this.state.course_categories.length - 1 === index && "border-b"}`}>
                                                        <NavLink to={"/course/" + item.slug} className="w-100 d-block"
                                                                 key={item.course_id}>
                                                            <div className="d-flex align-items-flex">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src={item.image}
                                                                        alt={item.name}
                                                                        title={item.name}/>
                                                                </div>
                                                                <span
                                                                    className="my-auto ml-1">{item.name}</span>
                                                            </div>
                                                        </NavLink>
                                                    </ListGroup.Item>
                                                )
                                            }
                                        )}
                                    </> : null
                                }
                                {this.state.articles.length > 0 ?
                                    <>
                                        <label className="d-flex justify-content-between">
                                            <span>مقاله ها</span>
                                            <NavLink to={"/search?s=" + this.state.searchValue}
                                                     className="bg-transparent border-0">مشاهده بیشتر</NavLink>
                                        </label>
                                        {this.state.articles.map((item, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        className={`w-100 ${this.state.course_categories.length - 1 === index && "border-b"}`}>
                                                        <NavLink to={"/article/" + item.slug} className="w-100 d-block"
                                                                 key={item.article_id}>
                                                            <div className="d-flex align-items-flex">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src={item.image}
                                                                        alt={item.title}
                                                                        title={item.title}/>
                                                                </div>
                                                                <span
                                                                    className="my-auto ml-1">{item.title}</span>
                                                            </div>
                                                        </NavLink>
                                                    </ListGroup.Item>
                                                )
                                            }
                                        )}
                                    </> : null
                                }
                            </ListGroup> : null
                        }
                    </div>
                }
            </>

        const search =
            <div className={"web-search"}>
                <input
                    className="search-box"
                    type="text"
                    placeholder="جستجو..."
                    onKeyDown={this.handleKeyDown}
                    onMouseEnter={() => this.closeResults()}
                    onChange={(e) => this.searchHandler(e)}
                    aria-label="Input group example"
                    aria-describedby="btnGroupAddon2"
                />
            </div>;

        const support_modal = <Modal size="md" className="support-modal" centered isOpen={this.state.support}
                                     toggle={() => this.toggleSupport()}>
            <ModalHeader>
                <div className="icon-wrapper"><LiveHelpIcon/></div>
            </ModalHeader>
            <ModalBody>
                <h4 className="text-center">پشتیبانی آکادمی شطرنج گامبی</h4>
                <p className="text-center">برای ارتباط با ما بر روی پشتیبانی آکادمی شطرنج گامبی کلیک(لمس) کنید</p>
                <div className="d-flex justify-content-center">
                    <a href=" https://chat.whatsapp.com/DTREjflnkf2E0kiLbuR4cl"
                       target="_blank" rel="noopener noreferrer"
                       className="support-btn">
                        <div>
                            <img src={logo} alt="logo" title="logo" width={50}/>
                            <span>پشتیبانی آکادمی شطرنج گامبی</span>
                        </div>
                        <div>
                            <WhatsApp/>
                        </div>

                    </a>
                </div>
            </ModalBody>
        </Modal>
        const download_modal =
            <Modal size="md" centered isOpen={this.state.modal_download}
                   toggle={() => this.toggleModalDownload()}>
                <ModalHeader className="bg-primary d-flex justify-content-center">
                    دانلود اپلیکیشن مدرسه
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    Android
                                </div>
                                <img src={android} alt="android-logo" title="android-logo" width="20px"/>
                            </div>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank" rel="noopener noreferrer"
                               className="mt-1 d-flex justify-content-center">
                                <img src={play} className="app-download"
                                     alt="دانلود اپ برای اندروید" title="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank" rel="noopener noreferrer"
                               className="mt-1 d-flex justify-content-center">
                                <img src={bazaar} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                            <a href=""
                               title="دانلود اپ برای اندروید" target="_blank" rel="noopener noreferrer"
                               className="mt-1 d-flex justify-content-center">
                                <img src={myket} className="app-download"
                                     alt="دانلود اپ برای اندروید"/>
                            </a>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="d-flex justify-content-center">
                                <div className="my-auto mr-1 ml-1">
                                    IOS
                                </div>
                                <img src={apple} alt="apple-logo" width="20px"/>
                            </div>
                            <a href=""
                               target="_blank" rel="noopener noreferrer" className="mt-1 d-flex justify-content-center">
                                <img src={sibiranilogobanner} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" rel="noopener noreferrer"
                               className="mt-1 d-flex justify-content-center">
                                <img src={IappsBadgeGray} className="app-download"
                                     alt="download-app"/>
                            </a>
                            <a href="" target="_blank" rel="noopener noreferrer"
                               className="mt-1 d-flex justify-content-center">
                                <img src={anardoniBadgeDarkPersian} className="app-download"
                                     alt="download-app"/>
                            </a>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>;


        // console.log(window.location.pathname)

        // let is_home = this.props.location && this.props.location.pathname === "/";
        let Moderate = false;
        if (this.props.user.roles)
            Moderate = this.props.user.roles.includes(Roles.Moderate);
        return (
            <header className="fixed-menu">
                <BrowserView>
                    <div className="header-web w-100">
                        <div className="header">
                            <div className="down-header d-flex justify-content-center mx-0">
                                <div className='header-menu d-flex align-items-center justify-content-between'>
                                    <div className='header-logo'>
                                        <img src={logo} alt="logo"/>
                                    </div>
                                    <div className="w-100">
                                        <Menu/>
                                    </div>
                                    <div className='header-search'>
                                        <div className="search-box-header">
                                            <Icon.Search className="btn-search" onClick={this.handleSearchClick}/>
                                            {search}
                                        </div>
                                        <div className='border-header'></div>
                                        <div className='header-phone'>
                                            <a href="tel:" className='d-flex'>
                                                <Phone size={20}/>
                                                <span> </span>
                                            </a>
                                        </div>
                                        <div className="my-auto pr-1 d-flex position-relative login">
                                            {this.props.user.token ?
                                                <>
                                                    <ul className="nav navbar-nav navbar-nav-user w-100">
                                                        <UncontrolledDropdown tag="li"
                                                                              className="dropdown-user nav-item text-center">
                                                            <DropdownToggle tag="a"
                                                                            className="nav-link dropdown-user-link">
                                                                <div className="user-nav d-sm-flex d-none">
                                                                    {/*<span className="user-name text-bold-600">*/}
                                                                    {/*  {this.props.user.name + " " + this.props.user.lastname}*/}
                                                                    {/*</span>*/}
                                                                </div>
                                                                <span data-tour="user">
                                                      <img
                                                          src={this.props.user.photo}
                                                          className="round"
                                                          height="40"
                                                          width="40"
                                                          alt="avatar"
                                                      />
                                                    </span>
                                                            </DropdownToggle>
                                                            <DropdownMenu left>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#"
                                                                    onClick={this.goToPanel}>
                                                                    <Icon.User size={14} className="ml-50"/>
                                                                    <span className="align-middle">
                                                            پنل
                                                        </span>
                                                                </DropdownItem>
                                                                <DropdownItem divider/>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={this.exit}>
                                                                    <Icon.Power size={14} className="ml-50"/>
                                                                    <span className="align-middle">
                                                            <FormattedMessage id="Logout"/>
                                                        </span>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </ul>
                                                </>
                                                :
                                                <ContextLayout.Consumer>
                                                    {context => {
                                                        return (
                                                            <>
                                                                <div className="btn-login"
                                                                     onClick={() => context.showLoginModal(true)}>
                                                                    <HiOutlineUser size={20} className="user-icon"/>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    }
                                                </ContextLayout.Consumer>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className='header-mobile'>
                        <div>
                            <div className="header w-100">
                                <div className="d-flex flex-1 align-items-center pl-1 navbar-wrapper">
                                    <MobileNavbar
                                        logo={logo}
                                        search={searchbar}
                                    />
                                </div>
                                <div className='header-logo'>
                                    <img src={logo} alt="logo"/>
                                </div>
                                <div className="d-flex flex-1 pr-1 justify-content-end my-auto">
                                    {this.props.user.token ?
                                        <ul className="nav navbar-nav navbar-nav-user w-100" style={{
                                            direction: 'ltr',
                                            padding: '0px',
                                            flex: 1
                                        }}>
                                            <UncontrolledDropdown tag="li"
                                                                  className="dropdown-user nav-item text-center">
                                                <DropdownToggle tag="a"
                                                                className="nav-link dropdown-user-link d-flex flex-row align-items-center">
                                                    <div className="user-nav d-sm-flex d-none">
                                                  <span className="user-name text-bold-600 mx-1">
                                                    {this.props.user.name + " " + this.props.user.lastname}
                                                  </span>
                                                    </div>
                                                    <span data-tour="user">
                                      <img
                                          src={this.props.user.photo}
                                          className="round"
                                          height="40"
                                          width="40"
                                          alt="avatar"
                                      />
                                    </span>
                                                </DropdownToggle>
                                                <DropdownMenu left>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#"
                                                        onClick={this.goToPanel}>
                                                        <Icon.User size={14} className="ml-50"/>
                                                        <span className="align-middle">
                                            پنل
                                        </span>
                                                    </DropdownItem>
                                                    <DropdownItem divider/>
                                                    <DropdownItem
                                                        tag="a"
                                                        onClick={this.exit}>
                                                        <Icon.Power size={14} className="ml-50"/>
                                                        <span className="align-middle">
                                            <FormattedMessage id="Logout"/>
                                        </span>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ul>
                                        :
                                        <div className="user-mobile">
                                            <HiOutlineUser fontSize="large" style={{flex: 1}}
                                                           onClick={() => this.props.modalToggler()}/>
                                        </div>
                                    }
                                </div>
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <div className="search-wrapper-mobile my-auto">*/}
                        {/*        {search}*/}
                        {/*        <Icon.Search className="btn-search" onClick={this.handleSearchClick} size={30}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className='phone-bottom-header'>
                        <div>
                            <div id='div-container' className={window.location.pathname === '/' ? 'activeColor' : ''}
                                 onClick={() => history.push('/')}>
                                <AiFillHome/>
                                <p className={window.location.pathname === '/' ? 'activeColor' : ''}>صفحه اصلی </p>
                            </div>
                        </div>
                        <div>
                            {/*<span><RiSearch2Line/></span>*/}
                            {/*<p> جستجو</p>*/}
                            <div id='div-container'
                                 className={window.location.pathname === '/search' ? 'activeColor' : ''}
                                 onClick={() => history.push('/search?s=')}>
                                <RiSearch2Line/>
                                <p> جستجو </p>
                            </div>
                        </div>
                        <div>
                            <div id='div-container'>
                                <a href="tel:+989126585894" className='d-flex flex-column'>
                                    <span> <Phone/></span>
                                    <p>تماس </p>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div id='div-container'>
                                <a href="https://www.instagram.com/Bitabahmanzadeh_amozesh/"
                                   className='d-flex flex-column'>
                                    <span> <FaInstagram/></span>
                                    <p>اینستاگرام</p>
                                </a>
                            </div>
                        </div>

                        <div className='scroll-to-top' onClick={scrollWindowTop}>
                            <div><AiOutlineArrowUp/></div>
                        </div>
                    </div>
                </MobileView>
                {/*{support_modal}*/}
                {/*{download_modal}*/}
            </header>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth,
        UI: state.UI
    }
}
export default connect(mapStateToProps, {logout})(Header)
